var firebaseConfig = {
    apiKey: "AIzaSyDQWrR-4aqeH_LPrZmiHj32FqKlSY4Kz6Y",
    authDomain: "filmsocietynitt.firebaseapp.com",
    databaseURL: "https://filmsocietynitt-default-rtdb.firebaseio.com",
    projectId: "filmsocietynitt",
    storageBucket: "filmsocietynitt.appspot.com",
    messagingSenderId: "1030826971909",
    appId: "1:1030826971909:web:0af2f5c5c5bc6feaa15d6c",
    measurementId: "G-NP4LWDREF0"
  };

  export default firebaseConfig;